import React, { Component } from 'react'
import Layout from "../components/layout-minimal"
import { navigate } from "gatsby"
import Input from '../components/input';
import { v1 as uuidv1 } from 'uuid';
import { validateEmail } from "../util/validators.js"
import { setAutoLogout } from "../services/auth"
import Loader from 'react-loader-spinner'
import ImgNextGen from "../components/ImgNextGen"
import Image from "../../public/images/space-villian.png"
import Image2 from "../../public/images/theif.png"
import Step from "../../public/images/step-1.png"
import config from "../components/config"

class SignUp extends Component {
  state = {
    email: "",
    password: "",
    confirm_password: "",
    name: "",
    formIsValid: false,
    nameError: false,
    emailError: false,
    passwordError: false,
    passwordMatchError: false,
    failedMessage: "",
    loading: false,
  };

  validateForm = () => {
    let hasError = false
    let nameError = false
    let emailError = false
    let passwordError = false
    let passwordMatchError = false
    if (this.state.name === "") {
      nameError = true
      hasError = true
    }
    if (!validateEmail(this.state.email)) {
      emailError = true
      hasError = true
    }
    if (this.state.password.length < 5) {
      passwordError = true
      hasError = true
    }
    if (this.state.password !== this.state.confirm_password) {
      passwordMatchError = true
      hasError = true
    }
    this.setState({
      formIsValid: hasError,
      nameError: nameError,
      emailError: emailError,
      passwordError: passwordError,
      passwordMatchError: passwordMatchError
    })
    return !hasError
  }

  signupHandler = async (event, authData) => {
    event.preventDefault();
    this.setState({loading: true})
    if (!this.validateForm()) {
      window.scrollTo(0, 0);
      this.setState({loading: false})
      return
    }
    const newId = uuidv1();
    console.log(authData)
    return
    try {
      let response = await fetch(`${config.backend}/user/sign-up`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: newId,
        email: authData.email,
        name: authData.name,
        password: authData.password
      })
    })
    let res = await response.json();
    if (res.status === 422) {
      this.setState({failedMessage: res.message})
      window.scrollTo(0, 0);
      this.setState({loading: false})
      throw new Error("Validation failed. Make sure this email hasn't been used yet.")
    }
    localStorage.setItem('token', res.token);
    localStorage.setItem('userId', res.id);
    localStorage.setItem('name', res.name);
    localStorage.setItem('email', res.email);
    const remainingMilliseconds = 365 * 24 * 60 * 60 * 1000;
    const expiryDate = new Date(
      new Date().getTime() + remainingMilliseconds
    );
    localStorage.setItem('expiryDate', expiryDate.toISOString());
    setAutoLogout(remainingMilliseconds);

    this.setState({loading: false})

        // this.setState({ isAuth: false, authLoading: false });
    navigate('/secure/checkout', {
          state: {
            customer: res.customer,
            id: res.id,
            email: res.email
          }
        });
      }
      catch(err) {
        console.log(err);
    }
  };

inputChangeHandler = (input, value) => {
  this.setState({
      [input]: value
    })
  };


    render() {
      return(
      <Layout>
        <div style={{ display: "flex", justifyContent: "center", backgroundColor: "#03cea4" }}>
          <div style={{width: "30%"}}><ImgNextGen width="100%" fallback={Image2} /*srcWebp={config.img + "pixopixa-logo-1.webp"}*//></div>
          <form style={{ minHeight: '470px', width: "40%" }} onSubmit={e => this.signupHandler(e, this.state)}>
            <div style={{ textAlign: "center", backgroundColor: "#ffffff", borderRadius: "20px", boxShadow: "0px 8px 18px 5px rgba(0,0,0,0.6)" }}>
              <br />
              <h1>Create An Account</h1>
              {this.state.failedMessage ? <div style={{backgroundColor: "#FF4200", color: "#ffffff", padding: "0.5rem", fontSize: "1rem", border: "1px solid red", fontWeight: "600", marginBottom: "1rem" }}>{this.state.failedMessage}</div> : null}
              <input
                id="name"
                className="signup-input-element"
                type="text"
                name="name"
                placeholder="Name"
                // label="Name"
                // control="input"
                onChange={(e) => this.inputChangeHandler("name", e.target.value)}
                // error={this.state.nameError}
                // errorMessage="Please Enter Your Name"
                onClick={() => this.setState({nameError: false})}
                // onBlur={this.inputBlurHandler.bind(this, 'email')}
                // value={this.state.signupForm['email'].value}
                // valid={this.state.signupForm['email'].valid}
                // touched={this.state.signupForm['email'].touched}
              />
              <br />
              <br />
              <input
                id="email"
                className="signup-input-element"
                name="email"
                type="email"
                placeholder="Email Address"
                // label="Email"
                // control="input"
                onChange={(e) => this.inputChangeHandler("email", e.target.value)}
                // error={this.state.emailError}
                // errorMessage="Please Enter A Valid Email"
                onClick={() => this.setState({emailError: false})}
                // onBlur={this.inputBlurHandler.bind(this, 'email')}
                // value={this.state.signupForm['email'].value}
                // valid={this.state.signupForm['email'].valid}
                // touched={this.state.signupForm['email'].touched}
              />
              <br />
              <br />
              <input
                id="password"
                className="signup-input-element"
                type="password"
                name="password"
                placeholder="Password"
                // label="Password"
                // control="input"
                onChange={(e) => this.inputChangeHandler("password", e.target.value)}
                // error={this.state.passwordError}
                // errorMessage="Password is too short"
                onClick={() => this.setState({passwordError: false})}
                // onBlur={this.inputBlurHandler.bind(this, 'password')}
                // value={this.state.signupForm['password'].value}
                // valid={this.state.signupForm['password'].valid}
                // touched={this.state.signupForm['password'].touched}
              />
              <br />
              <br />
              <input
                id="confirm_password"
                className="signup-input-element"
                type="password"
                name="confirm_password"
                placeholder="Confirm Password"
                // label="Confirm Password"
                // control="input"
                onChange={(e) => this.inputChangeHandler("confirm_password", e.target.value)}
                // error={this.state.passwordMatchError}
                // errorMessage="Password Does Not Match"
                onClick={() => this.setState({passwordMatchError: false})}
                // onBlur={this.inputBlurHandler.bind(this, 'confirm_password')}
                // value={this.state.signupForm['confirm_password'].value}
                // valid={this.state.signupForm['confirm_password'].valid}
                // touched={this.state.signupForm['confirm_password'].touched}
              />
              <br />
              <br />
              {this.state.loading ?
              <div style={{textAlign: "center"}}><Loader
                  type="ThreeDots"
                  color="#0a3f93"
                  height={100}
                  width={100}
               /></div>
               :
              <button
                type="submit"
                className="cta-button"
              >
                Sign Up
              </button>
            }
            <br/>
            <br/>
            <ImgNextGen width="48%" fallback={Step} /*srcWebp={config.img + "pixopixa-logo-1.webp"}*//>
            <br/>
            <br/>
            </div>
            <br/>
            <br/>
          </form>
          <div style={{width: "30%"}}><ImgNextGen width="100%" fallback={Image} /*srcWebp={config.img + "pixopixa-logo-1.webp"}*//></div>
        </div>
      </Layout>
    )
  }
}
export default SignUp
